import React from 'react';
import logo from './logo.png';
import { FaLinkedin, FaEnvelope, FaBriefcase } from 'react-icons/fa';
import './Maintenance.css';

const Maintenance = () => {
  return (
    <div className="container">
    <img src={logo} alt="Logo" className="logo" />
      <h1 className="message">Site en maintenance.</h1>
      <p className="description">Nous nous excusons pour le désagrément. Nous restons disponible via les canaux ci dessous.</p>
      <div className="link-bar">
        <a href="https://www.linkedin.com/in/gstephano/" className="link" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="icon" /> LinkedIn
        </a>
        <a href="https://www.malt.fr/profile/gwendalstephano" className="link" target="_blank" rel="noopener noreferrer">
          <FaBriefcase className="icon" /> Malt
        </a>
        <a href="mailto:contact@izlk-consulting.fr" className="link" target="_blank" rel="noopener noreferrer">
          <FaEnvelope className="icon" /> contact@izlk-consulting.fr
        </a>
      </div>
      <footer className="footer">
        &copy; {new Date().getFullYear()} IZLK Consulting − All rights reserved.
      </footer>
    </div>
  );
};

export default Maintenance;
